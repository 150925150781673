<template>
  <div class="page-content">
    <!--price table style-1 start-->
    <div class="text-center mt-5">
      <h2 class="title">Messaging</h2>
    </div>

    <section class="bg-contain bg-pos-r pt-0" data-bg-img="images/bg/02.png">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="tab text-center">
              <div id="nav-tabContent" class="tab-content px-0 pb-0 pt-0">
                <div
                  id="tab1-1"
                  role="tabpanel"
                  class="tab-pane fade show active"
                >
                  <div class="row">
                    <div class="col-lg-4 col-md-12">
                      <div class="price-table">
                        <div class="price-header">
                          <h3 class="price-title">Basic</h3>
                        </div>
                        <img
                          class="img-center my-4"
                          src="/images/price/01.png"
                          alt=""
                        />
                        <div class="price-value">
                          <h2><small>KES </small>1 - 99,999</h2>
                        </div>
                        <div class="price-list">
                          <ul class="list-unstyled">
                            <li>
                              <i class="flaticon-tick"></i> Safaricom 0.8 /sms
                            </li>
                            <li> 
                              <i class="flaticon-tick"></i> Airtel 0.8 /sms
                            </li>
                            <li>
                              <i class="flaticon-tick"></i> Others 0.8 /sms
                            </li>
                          </ul>
                        </div>
                        <a class="btn btn-theme mt-5" href="#">
                          <span>Get Started</span>
                        </a>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-12 md-mt-5">
                      <div class="price-table">
                        <div class="price-header">
                          <h3 class="price-title">Premium</h3>
                        </div>
                        <img
                          class="img-center my-4"
                          src="/images/price/01.png"
                          alt=""
                        />
                        <div class="price-value">
                          <h2><small>KES </small>100,000 - 399,999</h2>
                        </div>
                        <div class="price-list">
                          <ul class="list-unstyled">
                            <ul class="list-unstyled">
                            <li>
                              <i class="flaticon-tick"></i> Safaricom 0.6 /sms
                            </li>
                            <li> 
                              <i class="flaticon-tick"></i> Airtel 0.6 /sms
                            </li>
                            <li>
                              <i class="flaticon-tick"></i> Others 0.6 /sms
                            </li>
                          </ul>
                          </ul>
                        </div>
                        <a class="btn btn-theme mt-5" href="#">
                          <span>Get Started</span>
                        </a>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-12 md-mt-5">
                      <div class="price-table">
                        <div class="price-header">
                          <h3 class="price-title">Max</h3>
                        </div>
                        <img
                          class="img-center my-4"
                          src="/images/price/01.png"
                          alt=""
                        />
                        <div class="price-value">
                          <h2><small>KES </small>400,000 <small>+</small></h2>
                        </div>
                        <div class="price-list">
                          <ul class="list-unstyled">
                            <ul class="list-unstyled">
                            <li>
                              <i class="flaticon-tick"></i> Safaricom 0.4 /sms
                            </li>
                            <li> 
                              <i class="flaticon-tick"></i> Airtel 0.4 /sms
                            </li>
                            <li>
                              <i class="flaticon-tick"></i> Others 0.4 /sms
                            </li>
                          </ul>
                          </ul>
                        </div>
                        <a class="btn btn-theme mt-5" href="#">
                          <span>Get Started</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!--price table style-1 end-->
  </div>
</template>

<script>
export default {};
</script>
